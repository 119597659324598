
import { get, getWithHeader, post } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IExaQAItem, IExaSku, IExaTask } from "@/state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import TableRootEntities from "@/components/TableRootEntities.vue";

interface IExaTestingEntity {
    name: string;
    users: string[],
    teacher: string,
    sku: string | null;
}

interface IExaTaskHistory {
    qas: IExaQAItem[];
    answers: number[][];
    isCompleted: boolean;
    resultScore: null | number;
    totalScore: number;
}


@Component({
    components: {
        EntityPropertiesEdit,
        TableRootEntities,
    }
})
export default class ExaQuestionListView extends Vue {
    testings: { history: IExaTaskHistory | null, testing: IExaTestingEntity }[] = [];
    search: string = "";



    headers: Array<any> = [
        {
            text: "Имя",
            align: "start",
            value: "name"
        },
        {
            text: 'Оценка',
            value: 'score'
        },
        {
            text: '',
            align: "end",
            value: 'actions'
        }
    ];

    created() {
        get(`/api/@examination/exa/runtime/histories/`).then(x => {
            this.testings = x.data;
            console.log("Success fetch your testings", this.testings);
        });
    }

    startSKU(id: string) {
        this.$router.push({ name: "exa_runtime_question_view", params: { id: id } });
    }

    caption(task: IExaTask) {
        return task.name;
    }

}
